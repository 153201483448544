import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useMeasure from "react-use-measure";
import urlFor from "../../utils/SanityImage";
import { Link } from "react-router-dom";
import "./CardCarousel.css";

const CARD_WIDTH = 350;
const CARD_HEIGHT = 400;
const MARGIN = 20;
const CARD_SIZE = CARD_WIDTH + MARGIN;

const BREAKPOINTS = {
    sm: 640,
    lg: 1024,
};

const CardCarousel = ({ page, title }) => {
    const [ref, { width }] = useMeasure();
    const [offset, setOffset] = useState(0);
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const CARD_BUFFER =
        width > BREAKPOINTS.lg ? 3 : width > BREAKPOINTS.sm ? 2 : 1;

    const CAN_SHIFT_LEFT = offset < 0;

    const CAN_SHIFT_RIGHT =
        Math.abs(offset) < CARD_SIZE * (articles.length - CARD_BUFFER);

    const shiftLeft = () => {
        if (!CAN_SHIFT_LEFT) {
            return;
        }
        setOffset((pv) => (pv += CARD_SIZE));
    };

    const shiftRight = () => {
        if (!CAN_SHIFT_RIGHT) {
            return;
        }
        setOffset((pv) => (pv -= CARD_SIZE));
    };

    useEffect(() => {
        setIsLoading(true);
        const projectId = 'fsyqboh0';
        const dataset = 'production';
        const query = `*[_type == "article" && page == "${page}" && title != "About us"]{
        title,
        "slug": slug.current,
        mainImage,
        summary,
        category,
        teaser,
        "url": "/${page}/" + categories[0]->slug.current + "/" + slug.current
    }`;
        const url = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=${encodeURIComponent(query)}`;

        const fetchArticles = async () => {
            try {
                const response = await fetch(url);
                const data = await response.json();
                if (data.result) {
                    setArticles(data.result);
                } else {
                    setError('No articles found for "tips".');
                }
            } catch (err) {
                console.error('Fetch error:', err);
                setError('Error loading articles');
            } finally {
                setIsLoading(false);
            }
        };

        fetchArticles();
    }, []);

    // console.log("articles: ", articles);

    return (
        <section className="" ref={ref}>
            <div className="relative overflow-hidden pb-4">
                {/* CARDS */}
                <div className="mx-auto">
                    <h2 className={"inspired-title text-center font-bold text-blue"}>{title}</h2>
                    <motion.div
                        animate={{
                            x: offset,
                        }}
                        className="flex"
                    >
                        {articles.map((article, index) => (
                            <Card key={article.id || index} article={article} />
                        ))}
                    </motion.div>
                </div>

                {/* BUTTONS */}
                <>
                    <motion.button
                        initial={false}
                        animate={{
                            x: CAN_SHIFT_LEFT ? "0%" : "-100%",
                        }}
                        className="absolute left-0 top-[55.4%] z-30 rounded-r-xl bg-blue p-3 pl-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pl-3"
                        onClick={shiftLeft}
                    >
                        <FiChevronLeft />
                    </motion.button>
                    <motion.button
                        initial={false}
                        animate={{
                            x: CAN_SHIFT_RIGHT ? "0%" : "100%",
                        }}
                        className="absolute right-0 top-[55.4%] z-30 rounded-l-xl bg-blue p-3 pr-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pr-3"
                        onClick={shiftRight}
                    >
                        <FiChevronRight />
                    </motion.button>
                </>
            </div>
        </section>
    );
};

const Card = ({ article }) => {
    return (
        <Link
            to={article.url}
            className="relative shrink-0 cursor-pointer rounded-2xl bg-white shadow-md transition-all hover:scale-[1.015] hover:shadow-lg overflow-hidden"
            style={{
                width: CARD_WIDTH,
                height: CARD_HEIGHT,
                marginRight: MARGIN,
            }}
        >
            {/* Image Container */}
            <div
                className="bg-cover bg-center h-2/3"
                style={{
                    backgroundImage: `url(${urlFor(article.mainImage)})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            ></div>

            {/* Text Container */}
            <div className="p-4 bg-white h-1/3 flex flex-col">
                <p className="card-article-title text-blue text-xl font-bold mt-0 mb-1">{article.title}</p>
                <p className="text-base text-black mt-0 leading-snug">{article.teaser}</p>
            </div>
        </Link>
    );
};

export default CardCarousel;
