

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "./GrayLinkContainer.css";
import friends from "../../assets/Images/friends.png";
import sad from "../../assets/Images/boy_sad.png";
import substance from "../../assets/Images/addiction_boy.png";
import innerCalm from "../../assets/Images/inner_calm.png";
import careYourself from "../../assets/Images/self_care.png";
import heroInspiration from "../../assets/Images/hero_inspiration.png";

const GrayLinkContainer = ({ category, section }) => {

  // console.log("category: ", category)

  const getImageForCategory = (title) => {
    switch (title?.toLowerCase()) {
      case "you and others": return friends;
      case "feelings and emotions": return sad;
      case "substance abuse": return substance;
      case "finding your inner calm": return innerCalm;
      case "taking care of yourself": return careYourself;
      case "inspiring stories": return heroInspiration;
      default: return undefined;
    }
  };

  return (
    <div className={`w-full bg-[#EFEFEF] p-3 mt-5 rounded md:w-1/3 shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.15)]`}>
      <h2 className="category-title-wrap">
        <Link
          className={"category-title mb-3 no-underline text-[18px] md:text-[25px] font-bold cat-headers"}
          to={`/${section}/${category.slug}`}
        >
          {category.title}
        </Link>
      </h2>
      <div className={"grid grid-cols-3 "}>
        <div className="w-full col-span-2">
          {category.articles.map((article, idx) => (
            <div key={idx + article.title} className={""}>
              <Link
                className={"cat-art-title no-underline text-[#000000] text-[16px] md:text-[18px]"}
                style={{ fontFamily: "Roboto, Montserrat, sans-serif", fontWeight: "400" }}
                to={`/${section}/${category.slug}/${article.slug}`}
              >
                {article.title}
              </Link>
            </div>
          ))}
        </div>
        <div className={"justify-self-end w-fit h-fit col-span-1 "}>
          <img
            className={"max-h-20 md:max-h-24"}
            src={getImageForCategory(category.title)}
            alt={category.title}
          />
        </div>
      </div>
    </div>
  );
};

export default GrayLinkContainer;
