import React from "react";
import "./FeelingHint.css";
import { motion } from 'framer-motion';
import { transformBodyWithLinks } from "../../../../data/Feelings/FeelingsData";

const FeelingHint = ({ feeling }) => {
    const animationVariants = {
        initial: { rotateX: -90, opacity: 0 },
        animate: { rotateX: 0, opacity: 1, transition: { duration: 0.6 } },
        exit: { rotateX: 90, opacity: 0, transition: { duration: 0.6 } }
    };

    const bodyContent = transformBodyWithLinks(feeling.body);

    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={animationVariants}
            className={"feeling-hint p-4 mb-4 md:mb-6 bg-light-blue rounded-md mt-[5px] md:mt-0 shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.15)]"}
        >
            <span
                style={{ lineHeight: "23px"}}
                className={"feeling-title text-left text-black text-[18px]"}
            >
                {feeling?.title}
            </span>
            {feeling && (
            <div className="feeling-body">
                {bodyContent.map((lineContent, lineIndex) => (
                    <p key={lineIndex}>
                        {lineContent.map((item, index) => (
                            <React.Fragment key={index}>{item}</React.Fragment>
                        ))}
                    </p>
                ))}
            </div>
        )}

        </motion.div>
    );
};

export default FeelingHint;
