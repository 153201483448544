import "./NeuButton.css";

const NeuButton = ({ children, title, titleColor, fontWeight, bg }) => {
    return (
        <button
            // style={{ backgroundColor: bg, borderRadius: '15px' }}
            className={`
            neuButton
            px-4 py-2
            flex items-center gap-2
            text-slate-500
            shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.3)]
            transition-all
            rounded-[15px]
            bg-[#2469A6]
            hover:bg-[#004888]
    `}
        >
            {children ? children : <span style={{ color: titleColor, fontWeight: fontWeight }}>{title}</span>}
        </button>
    );
};

export default NeuButton;
