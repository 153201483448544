import React, { useContext, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./SearchBar.css";

const SearchBar = ({ setShowSearchBar }) => {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission
        const searchInput = e.target.elements.searchInput.value.trim(); // Get the trimmed value of the search input
        if (searchInput) {
            // Redirect to the search results page with the search query parameter
            navigate(`/search-results?q=${encodeURIComponent(searchInput)}`);
            setShowSearchBar(false); // Assuming this is a method to close/hide the search bar
        }
    };


    return (
        <div className="searchWrapper mx-auto w-full bg-[#EDF3F8] flex flex-row">
            <div className="search-x w-full flex items-center top-0 left-0 right-0 z-40">
                {/* <div className={styles.triangle}></div> */}
                <div className="searchContainer">
                    <form className="searchForm flex items-center" onSubmit={handleSubmit}>
                        <input
                            aria-label="Search Text Field"
                            autoFocus={true}
                            name="searchInput"
                            // ref={searchRef}
                            className="searchInput px-4 text-l h-9 w-11/12 translate-x-2 border rounded-full"
                            type="text"
                            placeholder="Type in keywords you want to search..."
                        />
                        <button className={"-translate-x-8"} >
                            <svg
                                width="20"
                                height="17"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.875 19.875L13.6251 13.625M15.7083 8.41667C15.7083 12.4437 12.4437 15.7083 8.41667 15.7083C4.38959 15.7083 1.125 12.4437 1.125 8.41667C1.125 4.38959 4.38959 1.125 8.41667 1.125C12.4437 1.125 15.7083 4.38959 15.7083 8.41667Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </form>
                    <div className="subInput">
                        <div className="ex">
                            Search examples:&nbsp;
                        </div>
                        <div className="exs">
                            <Link to="/info-advice/substance-abuse/addiction" onClick={() => setShowSearchBar(false)} className="underlinedWord">addiction</Link>,&nbsp;
                            <Link to="/info-advice/feelings-and-emotions/anxiety" onClick={() => setShowSearchBar(false)} className="underlinedWord">anxiety</Link>,&nbsp;
                            <Link to="/info-advice/substance-abuse/drugs" onClick={() => setShowSearchBar(false)} className="underlinedWord">drugs</Link>,&nbsp;
                            <Link to="/tips/null/calming-breathing-exercises" onClick={() => setShowSearchBar(false)} className="underlinedWord">breathing exercises</Link>
                        </div>
                    </div>

                </div>
                <div className="xtriangle">
                    <div className="triangle"></div>
                    <div
                        className="x"
                        onClick={() => setShowSearchBar(false)}
                    >
                        <i className="fa-solid fa-xmark fa-lg"></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
