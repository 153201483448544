import React, { Ref, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./Article.css"
import FadeUpLeft from "../../utils/FadeUpLeft";
import FadeUpRight from "../../utils/FadeUpRight";
import FadeRight from "../../utils/FadeRight";
import FadeLeft from "../../utils/FadeLeft";
import BlueContainerContent from "../BlueContainerContent/blueContainerContent";
import Paragraph from "../Paragraph/Paragraph";
import SubParagraph from "../SubParagraph/subParagraph";
import urlFor from "../../utils/SanityImage";
import BottomContainerContent from "../BottomContainerContent/BottomContainerContent";
import CardCarousel from "../CardCarousel/CardCarousel";

const screenConfig = {
    anxiety: {
        imageRanges: [
            { maxWidth: 815, values: [14, 15, 16, 17, 18] },
            { maxWidth: 932, values: [14, 15, 16, 17, 18, 19] },
            { maxWidth: 1000, values: [14, 15, 16, 17, 18] },
            { maxWidth: 1130, values: [14, 15, 16, 17, 18, 19] },
            { maxWidth: 1250, values: [14, 15, 16, 17, 18, 19, 20] },
            // Add additional ranges as needed
        ],
        imageDefaultValues: [14, 15, 16, 17, 18, 19, 20, 21],
        blueRanges: [],
        blueDefaultValues: [1, 2, 3, 4, 17, 18, 19, 20, 21]

    },
    depression: {
        // Define ranges and values for Depression
        imageRanges: [
            { maxWidth: 1125, values: [13, 14] },
        ],
        imageDefaultValues: [13, 14, 15],
        blueRanges: [
            { maxWidth: 792, values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
            { maxWidth: 1020, values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] },
            { maxWidth: 1200, values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }
        ],
        blueDefaultValues: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    },
    "abusive-relationships": {
        imageRanges: [

        ],
        imageDefaultValues: [3, 4, 5, 6, 7, 8],
        blueRanges: [
            { maxWidth: 878, values: [10, 11, 12, 13, 14, 15] },
            { maxWidth: 1194, values: [10, 11, 12, 13, 14] },
            { maxWidth: 1240, values: [10, 11, 12, 13] }
        ],
        blueDefaultValues: [10, 11, 12, 13, 14]
    },
    relationships: {
        imageRanges: [
            {}
        ],
        imageDefaultValues: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        blueRanges: [

        ],
        blueDefaultValues: []
    },
    "self-identity": {
        imageRanges: [
            {}
        ],
        imageDefaultValues: [],
        blueRanges: [

        ],
        blueDefaultValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    drugs: {
        imageRanges: [
            { maxWidth: 1070, values: [16, 17, 18, 19] }
        ],
        imageDefaultValues: [16, 17, 18, 19, 20, 21],
        blueRanges: [

        ],
        blueDefaultValues: [1, 2, 3, 4]
    },
    addiction: {
        imageRanges: [
            {}
        ],
        imageDefaultValues: [4, 5, 6, 7, 8, 9, 10],
        blueRanges: [

        ],
        blueDefaultValues: [1, 2, 3]
    },
    alcohol: {
        imageRanges: [
            { maxWidth: 855, values: [20, 21] }
        ],
        imageDefaultValues: [20, 21, 22, 23, 24, 25],
        blueRanges: [
            { maxWidth: 892, values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16] },
            { maxWidth: 1000, values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] },
            { maxWidth: 1121, values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] }
        ],
        blueDefaultValues: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13]
    },
    sleep: {
        imageRanges: [
            {}
        ],
        imageDefaultValues: [],
        blueRanges: [

        ],
        blueDefaultValues: [1, 2, 3, 4, 5, 6]
    },
    "boost-your-mood": {
        imageRanges: [
            { maxWidth: 845, values: [3] },
            { maxWidth: 1100, values: [3, 4] }
        ],
        imageDefaultValues: [3, 4, 5],
        blueRanges: [

        ],
        blueDefaultValues: []
    },
    "self-care": {
        imageRanges: [

        ],
        imageDefaultValues: [],
        blueRanges: [
            { maxWidth: 955, values: [3, 4, 5] },
            { maxWidth: 1060, values: [3, 4, 5, 6] }
        ],
        blueDefaultValues: [3, 4, 5]
    },
    "basics-of-a-healthy-diet": {
        imageRanges: [

        ],
        imageDefaultValues: [3, 4, 5, 6, 7],
        blueRanges: [

        ],
        blueDefaultValues: []
    },
    "physical-exercise": {
        imageRanges: [
            { maxWidth: 840, values: [14, 15, 16, 17, 18, 25, 26, 27, 28, 29, 30, 31, 45, 46] },
            { maxWidth: 1100, values: [14, 15, 16, 17, 18, 25, 26, 27, 28, 29, 30, 31, 45, 46, 47] },
            { maxWidth: 1240, values: [14, 15, 16, 17, 25, 26, 27, 28, 29, 30, 31, 45, 46, 47, 48, 49] }
        ],
        imageDefaultValues: [14, 15, 16, 17, 18, 25, 26, 27, 28, 29, 30, 31, 45, 46, 47, 48, 49],
        blueRanges: [

        ],
        blueDefaultValues: []
    },
    // Add more articles as needed
};

const Article = () => {
    const [article, setArticle] = useState(null);
    const [paragraphs, setParagraphs] = useState([]);
    const [blueContainerContent, setBlueContainerContent] = useState([]);
    const [bottomContainerContent, setBottomContainerContent] = useState([]);
    const [grayLink, setGrayLink] = useState(null);
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [error, setError] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dynamicHeight, setDynamicHeight] = useState('50vh');
    // const { search } = useLocation();
    // const searchParams = new URLSearchParams(search);
    // console.log("searchP: ", searchParams);
    // const searchTerm = searchParams.get('search');
    // console.log("searchterm: ", searchTerm);

    const [paragraphImage, setParagraphImage] = useState({
        anxiety: screenConfig.anxiety.imageDefaultValues,
        depression: screenConfig.depression.imageDefaultValues,
        "abusive-relationships": screenConfig['abusive-relationships'].imageDefaultValues,
        relationships: screenConfig.relationships.imageDefaultValues,
        "self-identity": screenConfig['self-identity'].imageDefaultValues,
        drugs: screenConfig.drugs.imageDefaultValues,
        addiction: screenConfig.addiction.imageDefaultValues,
        alcohol: screenConfig.alcohol.imageDefaultValues,
        sleep: screenConfig.sleep.imageDefaultValues,
        "boost-your-mood": screenConfig['boost-your-mood'].imageDefaultValues,
        "self-care": screenConfig['self-care'].imageDefaultValues,
        "basics-of-a-healthy-diet": screenConfig["basics-of-a-healthy-diet"].imageDefaultValues,
        "physical-exercise": screenConfig["physical-exercise"].imageDefaultValues,
        // ... other articles
    });

    const [paragraphBlues, setParagraphBlues] = useState({
        anxiety: screenConfig.anxiety.blueDefaultValues,
        depression: screenConfig.depression.blueDefaultValues,
        "abusive-relationships": screenConfig['abusive-relationships'].blueDefaultValues,
        relationships: screenConfig.relationships.blueDefaultValues,
        "self-identity": screenConfig['self-identity'].blueDefaultValues,
        drugs: screenConfig.drugs.blueDefaultValues,
        addiction: screenConfig.addiction.blueDefaultValues,
        alcohol: screenConfig.alcohol.blueDefaultValues,
        sleep: screenConfig.sleep.blueDefaultValues,
        "boost-your-mood": screenConfig['boost-your-mood'].blueDefaultValues,
        "self-care": screenConfig['self-care'].blueDefaultValues,
        "basics-of-a-healthy-diet": screenConfig["basics-of-a-healthy-diet"].blueDefaultValues,
        "physical-exercise": screenConfig["physical-exercise"].imageDefaultValues,
        // ... other articles
    });

    const [imageBlue, setImageBlue] = useState({
        anxiety: [3],
        depression: [],
        "abusive-relationships": [],
        relationships: [],
        "self-identity": [],
        drugs: [],
        addiction: [],
        alcohol: [],
        sleep: [],
        "boost-your-mood": [],
        "self-care": [],
        "basics-of-a-healthy-diet": [],
        "physical-exercise": []
    })

    const [blueWithTwoPs, setBlueWithTwoPs] = useState({
        depression: [1, 2],
        alcohol: [4, 5],
        "self-care": [2, 3]
        // sleep: [2, 3]
    })

    const [blueWithThreePs, setBlueWithThreePs] = useState({
        "self-identity": [1, 2, 3],
        drugs: [1, 2],
        alcohol: [1, 2, 3],
        sleep: [1, 2, 3]
    });

    const [blueOnTop, setBlueOnTop] = useState({
        "self-identity": true
    });

    let { articleTitle } = useParams();
    const normalizedArticleTitle = articleTitle.replace(/\s+/g, '-').toLowerCase();
    let { pathname } = useLocation();
    const segments = pathname.split('/'); // This will split the pathname into an array
    const page = segments[1];

    // console.log("articleTitle: ", articleTitle);

    // Functions to pass into the prop
    const shouldGroupImageWithBlueContainer = (serialNum) => {
        return imageBlue[normalizedArticleTitle]?.includes(serialNum);
    };

    const isStartOfTwoParagraphGroup = (serialNum) => {
        return blueWithTwoPs[normalizedArticleTitle]?.includes(serialNum);
    };

    const isThreeParagraphGroup = (serialNum) => {
        return blueWithThreePs[normalizedArticleTitle]?.includes(serialNum);
    }

    // Handle main image and subP sizes based off of screen dimensions
    const handleResize = () => {
        const screenWidth = window.innerWidth;
        setWindowWidth(screenWidth);

        const screenHeight = window.innerHeight;
        if (screenHeight > 1100) {
            setDynamicHeight('45vh'); // Larger height for shorter screens
        } else if (screenHeight < 500) {
            setDynamicHeight('20vh')
        } else {
            setDynamicHeight('40vh')
        }

        const updateValuesForArticle = (articleKey) => {
            const config = screenConfig[articleKey];
            if (!config) return; // Exit if no config found

            const updateValues = (type) => {
                const ranges = config[`${type}Ranges`];
                let values = config[`${type}DefaultValues`];

                // Check if ranges is defined and iterable
                if (Array.isArray(ranges)) {
                    for (const range of ranges) {
                        if (screenWidth <= range.maxWidth) {
                            values = range.values;
                            break;
                        }
                    }
                }
                return values;
            };

            setParagraphImage(prevImages => ({
                ...prevImages,
                [articleKey]: updateValues('image')
            }));

            setParagraphBlues(prevBlues => ({
                ...prevBlues,
                [articleKey]: updateValues('blue')
            }));
        };
        // Update values for all articles
        Object.keys(screenConfig).forEach(updateValuesForArticle);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect(() => {
    //     // Ensure all data is loaded
    //     if (article && paragraphs.length && blueContainerContent.length && bottomContainerContent.length && searchTerm) {
    //         // A simple function to create a selector for the term
    //         const escapeRegExp = text => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    //         const searchTermRegex = new RegExp(escapeRegExp(searchTerm), 'i');

    //         // Function to scroll to element
    //         const scrollToElement = element => {
    //             if (element) {
    //                 window.scrollTo({
    //                     top: element.offsetTop - 100, // Adjust based on your header size or desired spacing
    //                     behavior: "smooth"
    //                 });
    //             }
    //         };

    //         // Check paragraphs for the search term
    //         const paragraphElement = paragraphs.find(p => searchTermRegex.test(p.body));
    //         if (paragraphElement) {
    //             const element = document.getElementById(paragraphElement.title.replace(/\s+/g, '-').toLowerCase());
    //             scrollToElement(element);
    //             return; // Stop once the first match is found and scrolled to
    //         }

    //         // Repeat the above logic for blueContainerContent and bottomContainerContent
    //         // Note: Adjust the logic to match how these contents are rendered and their IDs or markers are assigned
    //     }
    // }, [article, paragraphs, blueContainerContent, bottomContainerContent, searchTerm]);

    // Fetch Article
    useEffect(() => {
        // console.log("useEffect is running");
        const projectId = 'fsyqboh0';
        const dataset = 'production';
        const query = encodeURIComponent(`*[_type == "article" && slug.current == "${articleTitle}"]`);
        const url = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=${query}`;

        fetch(url)
            .then(res => res.json())
            .then(({ result }) => {
                // console.log('Fetch result:', result);
                setArticle(result[0]);
                // Once we have the article, fetch the paragraphs
                const paragraphIds = result[0]?.paragraph?.map(p => p._ref);
                if (paragraphIds?.length > 0) {
                    const paragraphsQuery = encodeURIComponent(`*[_id in [${paragraphIds.map(id => `"${id}"`).join(',')}]]`);
                    const paragraphsUrl = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=${paragraphsQuery}`;

                    fetch(paragraphsUrl)
                        .then(res => res.json())
                        .then(({ result: paragraphResult }) => {
                            // console.log('Paragraphs:', paragraphResult);
                            const sortedParagraphs = paragraphResult.sort((a, b) => a.serial_num - b.serial_num);
                            setParagraphs(sortedParagraphs);
                        })
                        .catch(err => {
                            console.error('Fetch error for paragraphs:', err);
                            setError('Error loading paragraph data');
                        });
                }
                // Fetch blueContainerContent
                const blueContainerIds = result[0]?.blueContainerContent?.map(bc => bc._ref);
                if (result[0]?.blueContainerContent && blueContainerIds.length > 0) {
                    const blueContainerQuery = encodeURIComponent(`*[_id in [${blueContainerIds.map(id => `"${id}"`).join(',')}]]`);
                    const blueContainerUrl = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=${blueContainerQuery}`;

                    fetch(blueContainerUrl)
                        .then(res => res.json())
                        .then(({ result: blueContainerResult }) => {
                            // console.log('Blue Container Content:', blueContainerResult);
                            setBlueContainerContent(blueContainerResult);
                        })
                        .catch(err => {
                            console.error('Fetch error for blue container content:', err);
                            setError('Error loading blue container data');
                        });
                }
                // Fetch bottomContainerContent
                const bottomContainerIds = result[0]?.BottomContainerContent?.map(bc => bc._ref);
                // console.log("bcIDs: ", bottomContainerIds);
                if (result[0]?.BottomContainerContent && bottomContainerIds.length > 0) {
                    const bottomContainerQuery = encodeURIComponent(`*[_id in [${bottomContainerIds.map(id => `"${id}"`).join(',')}]]`);
                    const bottomContainerUrl = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=${bottomContainerQuery}`;

                    fetch(bottomContainerUrl)
                        .then(res => res.json())
                        .then(({ result: bottomContainerResult }) => {
                            console.log('Bottom Container Content:', bottomContainerResult);
                            setBottomContainerContent(bottomContainerResult);
                        })
                        .catch(err => {
                            console.error('Fetch error for bottom container content:', err);
                            setError('Error loading bottom container data');
                        });
                }
            })
            .catch(err => {
                console.error('Fetch error:', err);
                setError('Error loading data');
            });
    }, [articleTitle]);
    // console.log("bcc: ", bottomContainerContent)

    // console.log("article: ", article);

    const backgroundImageUrl = article && article.mainImage ? urlFor(article.mainImage) : '';

    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: dynamicHeight,
    };

    // Scroll to a section helper
    const scrollToSection = (event, sectionId) => {
        event.preventDefault(); // Prevent default anchor link behavior

        const section = document.getElementById(sectionId);
        if (section) {
            const offsetTop = section.offsetTop - 100;
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    const renderParagraphs = () => {
        const paragraphElements = [];
        let skipCount = 0;

        paragraphs.forEach((p, index) => {
            if (skipCount > 0) {
                skipCount--;
                return;
            }

            const isStartOfGroup = isStartOfTwoParagraphGroup(p.serial_num);
            const isStartOfThreeParagraphGroup = isThreeParagraphGroup(p.serial_num);
            const nextParagraph = paragraphs[index + 1];
            const nextNextParagraph = paragraphs[index + 2];
            // console.log("nextNext: ", nextNextParagraph);
            const imageBlock = nextNextParagraph?.body?.find(block => block._type === 'image');
            // console.log("imageBlock: ", imageBlock);
            const hasImage = Boolean(imageBlock);

            if (isStartOfThreeParagraphGroup && nextParagraph && nextNextParagraph) {
                paragraphElements.push(
                    <div className="twoP-b-wrapper">
                        <div className="threeP-b-container">
                            <div className="two-paragraph-wrapper">
                                <Paragraph
                                    paragraph={p}
                                    paragraphImage={paragraphImage}
                                    paragraphBlues={paragraphBlues}
                                    blueContainerContent={blueContainerContent}
                                    articleTitle={normalizedArticleTitle}
                                    urlFor={urlFor}
                                    groupImageWithBlueContainer={shouldGroupImageWithBlueContainer(p.serial_num)}
                                    isGrouped={true}
                                    blueOnTop={blueOnTop}
                                />
                                <Paragraph
                                    paragraph={{ ...nextParagraph, body: nextParagraph.body.filter(subP => paragraphBlues[normalizedArticleTitle]?.includes(subP.serial_num)) }}
                                    paragraphImage={paragraphImage}
                                    paragraphBlues={paragraphBlues}
                                    blueContainerContent={blueContainerContent}
                                    articleTitle={normalizedArticleTitle}
                                    urlFor={urlFor}
                                    groupImageWithBlueContainer={shouldGroupImageWithBlueContainer(nextParagraph.serial_num)}
                                    isGrouped={true}
                                    blueOnTop={blueOnTop}
                                />
                                <Paragraph
                                    paragraph={{ ...nextNextParagraph, body: nextNextParagraph.body.filter(subP => paragraphBlues[normalizedArticleTitle]?.includes(subP.serial_num)) }}
                                    paragraphImage={paragraphImage}
                                    paragraphBlues={paragraphBlues}
                                    blueContainerContent={blueContainerContent}
                                    articleTitle={normalizedArticleTitle}
                                    urlFor={urlFor}
                                    groupImageWithBlueContainer={shouldGroupImageWithBlueContainer(nextNextParagraph.serial_num)}
                                    isGrouped={true}
                                    blueOnTop={blueOnTop}
                                />
                            </div>
                            <div className='threeP-image-and-blue'>
                                <div>
                                    <BlueContainerContent blueContainerContent={blueContainerContent} articleTitle={normalizedArticleTitle} containerIndex={0} />
                                </div>
                                {hasImage && <div className="rounded-lg threeP-i-container">
                                    <img id="art-image" className="rounded-lg" src={urlFor(imageBlock?.asset)} alt={`${articleTitle} Image`} />
                                </div>}
                            </div>
                        </div>
                        {nextNextParagraph.body.filter(subP => !paragraphBlues[normalizedArticleTitle]?.includes(subP.serial_num)).map(subP => (
                            <div>
                                <SubParagraph key={subP._key} subParagraph={subP} className="subP-full" />
                            </div>
                        ))}
                    </div>
                );
                skipCount = 2; // Skipping the next two paragraphs
            } else if (isStartOfGroup && nextParagraph) {
                paragraphElements.push(
                    <div className="twoP-b-wrapper">
                        <div className="twoP-b-container" key={p._id}>
                            <div className="two-paragraph-wrapper">
                                <Paragraph
                                    paragraph={p}
                                    paragraphImage={paragraphImage}
                                    paragraphBlues={paragraphBlues}
                                    blueContainerContent={blueContainerContent}
                                    articleTitle={normalizedArticleTitle}
                                    urlFor={urlFor}
                                    groupImageWithBlueContainer={shouldGroupImageWithBlueContainer(p.serial_num)}
                                    isGrouped={true}
                                    blueOnTop={blueOnTop}
                                />
                                <Paragraph
                                    paragraph={{ ...nextParagraph, body: nextParagraph.body.filter(subP => paragraphBlues[normalizedArticleTitle]?.includes(subP.serial_num)) }}
                                    paragraphImage={paragraphImage}
                                    paragraphBlues={paragraphBlues}
                                    blueContainerContent={blueContainerContent}
                                    articleTitle={normalizedArticleTitle}
                                    urlFor={urlFor}
                                    groupImageWithBlueContainer={shouldGroupImageWithBlueContainer(nextParagraph.serial_num)}
                                    isGrouped={true}
                                    blueOnTop={blueOnTop}
                                />
                            </div>
                            <div className="twoP-blue">
                                <BlueContainerContent blueContainerContent={blueContainerContent} containerIndex={blueContainerContent[1] ? 1 : 0} articleTitle={normalizedArticleTitle} />
                            </div>
                        </div>
                        {nextParagraph.body.filter(subP => !paragraphBlues[normalizedArticleTitle]?.includes(subP.serial_num)).map(subP => (
                            <div>
                                <SubParagraph key={subP._key} subParagraph={subP} className="subP-full" />
                            </div>
                        ))}
                    </div>
                );
                skipCount = 1; // Skip the next paragraph as it's already included
            } else {
                paragraphElements.push(
                    <Paragraph
                        key={p._id}
                        paragraph={p}
                        paragraphImage={paragraphImage}
                        paragraphBlues={paragraphBlues}
                        blueContainerContent={blueContainerContent}
                        articleTitle={normalizedArticleTitle}
                        urlFor={urlFor}
                        groupImageWithBlueContainer={shouldGroupImageWithBlueContainer(p.serial_num)}
                        isGrouped={false}
                        blueOnTop={blueOnTop}
                    />
                );
            }
        });

        return paragraphElements;
    };

    return (
        <>
            <div className={"article-wrapper"}>
                {windowWidth > 768 ? (
                    <div className={"article-top"} style={backgroundImageStyle}>
                        <div className="article-bg">
                            <div className={"article-title-sum"}>
                                <FadeUpRight className={"title-sum bg-light-blue py-3 px-4 rounded-lg "}>
                                    <h1 className={`text-left  font-bold mx-auto`}>
                                        {article?.title}
                                    </h1>
                                    <p style={{ padding: "0 0" }}>
                                        {article?.summary}
                                    </p>
                                </FadeUpRight>
                                <div></div>
                            </div>
                            <div className={"article-links "}>
                                <div className={"md:col-span-2 lg:col-span-2"}></div>
                                <div className={"md:col-span-2 lg:col-span-1"}>
                                    <FadeUpLeft className={"what-find-box w-full bg-[#ebf2f0] rounded-lg  py-3 px-4 "}>
                                        <h2 className={"find-title text-blue font-bold heading-futura-bold"}>What you’ll find on this page</h2>
                                        <div className="links-container">
                                            {paragraphs?.map((p) => (
                                                <a key={p.id} className="article-link" href="#" onClick={(e) => scrollToSection(e, p.title?.replace(/\s+/g, '-').toLowerCase())}>{p.title}</a>
                                            ))}
                                            {blueContainerContent?.map((b) => (
                                                <a key={b.id} className="article-link" href="#" onClick={(e) => scrollToSection(e, b.title?.replace(/\s+/g, '-').toLowerCase())}>{b.title}</a>
                                            ))}
                                            {bottomContainerContent?.map((b) => (
                                                <a key={b.id} className="article-link" href="#" onClick={(e) => scrollToSection(e, b.title?.replace(/\s+/g, '-').toLowerCase())}>{b.title}</a>
                                            ))}
                                        </div>
                                    </FadeUpLeft>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="small-screen-article-top" style={backgroundImageStyle}></div>
                        {/* <div className="small-article-bg"></div> */}
                        <FadeRight className={"small-article-title-sum"}>
                            <div className={"bg-light-blue py-3 px-4 rounded-lg "}>
                                <h1 className={`text-left  font-bold mx-auto`}>
                                    {article?.title}
                                </h1>

                                <p style={{ padding: "0 0" }}>
                                    {article?.summary}
                                </p>
                            </div>
                            <div></div>
                        </FadeRight>
                        <FadeLeft className={"small-article-links"}>
                            <div className={"small-what-find-box w-full bg-[#ebf2f0] rounded-lg  py-3 px-4 "}>
                                <h2 className={"text-blue font-bold heading-futura-bold"}>What you’ll find on this page</h2>
                                <div className="links-container">
                                    {paragraphs?.map((b) => (
                                        <a key={b.id} className="article-link" href="#" onClick={(e) => scrollToSection(e, b.title?.replace(/\s+/g, '-').toLowerCase())}>{b.title}</a>
                                    ))}
                                    {blueContainerContent?.map((b) => (
                                        <a key={b.id} className="article-link" href="#" onClick={(e) => scrollToSection(e, b.title?.replace(/\s+/g, '-').toLowerCase())}>{b.title}</a>
                                    ))}
                                    {bottomContainerContent?.map((b) => (
                                        <a key={b.id} className="article-link" href="#" onClick={(e) => scrollToSection(e, b.title?.replace(/\s+/g, '-').toLowerCase())}>{b.title}</a>
                                    ))}
                                </div>
                            </div>
                        </FadeLeft>
                    </>
                )}
                <div className={`article-content-wrapper ${normalizedArticleTitle === "about-us" ? "pb-[150px]" : ""}`}>
                    <div className="paragraphs-wrapper">
                        <div className="paragraphs-container">
                            {renderParagraphs()}
                        </div>
                        {bottomContainerContent &&
                            <div className={`bottom-content-wrapper ${bottomContainerContent.length === 4 ? 'four-items' : ''}`}>
                                {bottomContainerContent.map((bc, index) => (
                                    <BottomContainerContent bottomContainerContent={bc} containerIndex={index} key={index} articleTitle={normalizedArticleTitle} />
                                ))}
                            </div>
                        }
                    </div>
                </div>
                {normalizedArticleTitle != "about-us" &&
                    <div className="inspired-wrapper gradient-background pt-5">
                        <div className="inspired-container pb-[50px]">
                            <CardCarousel page={page} title={"This might also interest you"} />
                        </div>
                    </div>
                }

                {/*
            {problem && problem.yellowContainerContent?.length > 0 && (
                <div
                    className={
                        "w-full px-3 lg:max-w-screen-xl mx-auto lg:grid lg:grid-cols-3 lg:gap-4"
                    }
                >
                    {problem.yellowContainerContent.map((y, idx) => (
                        <div key={idx}>
                            <BottomBlueContainerComp
                                heightState={heightState}
                                setHeightState={heightStateHandler}
                                paragraph={y}
                            />
                        </div>
                    ))}
                </div>
            )}

            <div className={"mt-8 lg:mt-12"}>
                {category && <MightInterestYouComp category={category} />}
            </div>
            <section id={"contact-us"} className={"mt-16"}>
                <GradientCommunicationSection
                    url={undefined}
                    title={"Do you want to know about other topics?"}
                    buttonText={"Send us suggestions!"}
                />
            </section> */}
            </div >
        </>
    );
};

export default Article;
