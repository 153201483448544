import "./App.css";
import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/home";
import Nav from "./components/Nav/nav";
import Footer from "./components/Footer/Footer";
import ArticleHub from "./components/ArticleHub/ArticleHub";
import CategoryHub from "./components/CategoryHub/CategoryHub";
import Article from "./components/Articles/Article";
import { useLocation } from "react-router-dom";
import SearchResults from "./components/SearchResults/SearchResults";
import ScrollToTop from "./utils/ScrollToTop";
import Contact from "./components/Contact/Contact";

function App() {
  const location = useLocation();
  // console.log("location: ", location);

  return (
    <div className="main-body">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={location.pathname === "/about-us" ? "/:articleTitle" : "/:section/:category/:articleTitle"} element={<Article />} />
        <Route path="/:section" element={<ArticleHub />} />
        {/* <Route path="/tips" element={<ArticleHub />} /> */}
        <Route path="/:section/:category/:articleTitle" element={<Article />} />
        <Route path="/:section/:category" element={<CategoryHub/>}/>
        <Route path="/search-results" element={<SearchResults />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <ScrollToTop showBelow={300} />
      <Footer />
    </div>
  );
}

export default App;
