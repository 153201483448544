import "./home.css";
import Feeling from "./Feeling/Feeling";
import Inspired from "./Inspired/Inspired";

const Home = () => {
    return (
        <div className="home-wrapper">
            <div className="home-background">
                <div className="not-alone-card-wrapper">
                    <div duration={1000} className="not-alone-card">
                        <h1 className="not-alone-title">
                            You are not alone!
                        </h1>
                        <p className="not-alone-text">
                            No matter how you are feeling or what you are experiencing, we are here to help!
                        </p>
                    </div>
                </div>
            </div>
            <Feeling />
            <Inspired />
        </div >
    )
}

export default Home;
