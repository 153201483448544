import React, { useState } from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import "./nav.css";
import logo from "../../assets/Images/Logo.png"
import SearchButton from './SearchButton/SearchButton';
import SearchBar from './SearchBar/SearchBar';
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import abusiveRelationships from "../../assets/audiofiles/abusive-relationships.mp3"
import abusiveRelationshipsTwi from "../../assets/audiofiles/abusive-relationships_twi.mp3"
import addictionTwi from "../../assets/audiofiles/addiction_twi.mp3"
import addiction from "../../assets/audiofiles/addiction.mp3"
import alcoholTwi from "../../assets/audiofiles/alcohol_twi.mp3"
import alcohol from "../../assets/audiofiles/alcohol.mp3"
import anxietyTwi from "../../assets/audiofiles/anxiety_twi.mp3"
import anxiety from "../../assets/audiofiles/anxiety.mp3"
import basicsOfAHealthyDietTwi from "../../assets/audiofiles/basics-of-a-healthy-diet_twi.mp3"
import basicsOfAHealthyDiet from "../../assets/audiofiles/basics-of-a-healthy-diet.mp3"
import boostYourMoodTwi from "../../assets/audiofiles/boost-your-mood_twi.mp3"
import boostYourMood from "../../assets/audiofiles/boost-your-mood.mp3"
import calmingBreathingExercises_Twi from "../../assets/audiofiles/calming-breathing-exercises_twi.mp3"
import calmingBreathingExercises from "../../assets/audiofiles/calming-breathing-exercises.mp3"
import depressionTwi from "../../assets/audiofiles/depression_twi.mp3"
import depression from "../../assets/audiofiles/depression.mp3"
import drugsTwi from "../../assets/audiofiles/drugs_twi.mp3"
import drugs from "../../assets/audiofiles/drugs.mp3"
import groundingTechniquesTwi from "../../assets/audiofiles/grounding-techniques_twi.mp3"
import groundingTechniques from "../../assets/audiofiles/grounding-techniques.mp3"
import relationshipsTwi from "../../assets/audiofiles/relationships_twi.mp3"
import relationships from "../../assets/audiofiles/relationships.mp3"
import selfCareTwi from "../../assets/audiofiles/self-care_twi.mp3"
import selfCare from "../../assets/audiofiles/self-care.mp3"
import selfIdentityTwi from "../../assets/audiofiles/self-identity_twi.mp3"
import selfIdentity from "../../assets/audiofiles/self-identity.mp3"
import sleepTwi from "../../assets/audiofiles/sleep_twi.mp3"
import sleep from "../../assets/audiofiles/sleep.mp3"
import about from "../../assets/Images/about.png";
import aboutBlue from "../../assets/Images/about-blue.png";

const Nav = () => {
    const location = useLocation();
    const pathname = location.pathname.toLowerCase();
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const pathSegments = location.pathname.split('/').filter(segment => segment !== "");
    // Check if there are at least two parameters in the URL
    const hasAtLeastTwoParams = pathSegments.length >= 2;
    const hasAtLeastThreeParams = pathSegments.length >= 3;

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleSearchButtonClick = () => {
        setShowSearchBar(!showSearchBar);
    };

    const renderNavLinks = () => {

    }

    const isActive = (path) => pathname.includes(path);


    const articlePath = pathname.split("/")
    const englishAudioPath = articlePath[articlePath.length - 1] + ".mp3"
    const twiAudioPath = articlePath[articlePath.length - 1] + "_twi.mp3"
    const map = new Map()
    map.set('abusive-relationships.mp3', abusiveRelationships)
    map.set('abusive-relationships_twi.mp3', abusiveRelationshipsTwi)
    map.set('addiction_twi.mp3', addictionTwi)
    map.set('addiction.mp3', addiction)
    map.set('alcohol_twi.mp3', alcoholTwi)
    map.set('alcohol.mp3', alcohol)
    map.set('anxiety_twi.mp3', anxietyTwi)
    map.set('anxiety.mp3', anxiety)
    map.set('basics-of-a-healthy-diet_twi.mp3', basicsOfAHealthyDietTwi)
    map.set('basics-of-a-healthy-diet.mp3', basicsOfAHealthyDiet)
    map.set('boost-your-mood_twi.mp3', boostYourMoodTwi)
    map.set('boost-your-mood.mp3', boostYourMood)
    map.set('calming-breathing-exercises_twi.mp3', calmingBreathingExercises_Twi)
    map.set('calming-breathing-exercises.mp3', calmingBreathingExercises)
    map.set('depression_twi.mp3', depressionTwi)
    map.set('depression.mp3', depression)
    map.set('drugs_twi.mp3', drugsTwi)
    map.set('drugs.mp3', drugs)
    map.set('grounding-techniques_twi.mp3', groundingTechniquesTwi)
    map.set('grounding-techniques.mp3', groundingTechniques)
    map.set('relationships_twi.mp3', relationshipsTwi)
    map.set('relationships.mp3', relationships)
    map.set('self-care_twi.mp3', selfCareTwi)
    map.set('self-care.mp3', selfCare)
    map.set('self-identity_twi.mp3', selfIdentityTwi)
    map.set('self-identity.mp3', selfIdentity)
    map.set('sleep_twi.mp3', sleepTwi)
    map.set('sleep.mp3', sleep)

    function playAudio(isEnglish) {
        let audio = isEnglish ? document.getElementById("englishVoiceover") : document.getElementById("twiVoiceover")
        audio?.paused ? audio?.play() : audio?.pause()

    }

    return (
        <div className='nav-below drop shadow'>
            <div className={'nav-wrapper'}>
                <div className='nav-container'>
                    {windowWidth < 570 ? (
                        <div className='small-nav'>
                            <div className='logo-search'>
                                <Link to="/">
                                    <img src={logo} alt="Wohohiame Logo" className="logo" />
                                </Link>
                                <SearchButton onSearchClick={handleSearchButtonClick} />
                            </div>
                            <div className='small-links'>
                                <Link to="/" className={`nav-link ${pathname === "/" ? "active" : ""}`}>
                                    <span className={"flex items-center gap-[5px] pb-[3px]"}>
                                        <span>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M3.95833 9.5H2.375L9.5 2.375L16.625 9.5H15.0417"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M3.95898 9.5V15.0417C3.95898 15.4616 4.1258 15.8643 4.42273 16.1613C4.71966 16.4582 5.12239 16.625 5.54232 16.625H13.459C13.8789 16.625 14.2816 16.4582 14.5786 16.1613C14.8755 15.8643 15.0423 15.4616 15.0423 15.0417V9.5"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M7.125 16.6248V11.8748C7.125 11.4549 7.29182 11.0522 7.58875 10.7553C7.88568 10.4583 8.28841 10.2915 8.70833 10.2915H10.2917C10.7116 10.2915 11.1143 10.4583 11.4113 10.7553C11.7082 11.0522 11.875 11.4549 11.875 11.8748V16.6248"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span>Home</span>
                                    </span>
                                </Link>
                                {/* <Link onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} to="/about-us" className={`nav-link ${pathname === "/about-us" ? "active" : "inactive"}`}>
                                    <span className={"flex items-center gap-[5px] pb-[3px]"}>
                                        <img src={isHovered || pathname === "/about-us" ? aboutBlue : about} className='about-icon' />
                                        <span>About</span>
                                    </span>
                                </Link> */}
                                <Link to="/info-advice" className={`nav-link ${isActive("info-advice") ? "active" : ""}`}>
                                    <span className={"flex items-center gap-[5px] pb-[3px] tab-box"}>
                                        <span>
                                            <svg
                                                width="17"
                                                height="20"
                                                viewBox="0 0 17 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clipPath="url(#clip0_409_5379)">
                                                    <path
                                                        d="M10.0001 0.90918H2.72736C1.72736 0.90918 0.90918 1.72736 0.90918 2.72736V17.2728C0.90918 18.2728 1.72736 19.091 2.72736 19.091H13.6365C14.6365 19.091 15.4546 18.2728 15.4546 17.2728V6.36372L10.0001 0.90918Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M10 0.90918V6.36373H15.4545"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M11.8186 10.9092H4.5459"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M11.8186 14.5459H4.5459"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M6.36408 7.27246H5.45499H4.5459"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_409_5379">
                                                        <rect width="16.3636" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        <span>Info & Advice</span>
                                    </span>
                                </Link>
                                <Link to="/tips" className={`nav-link ${isActive("tips") ? "active" : ""}`}>
                                    <span className={"flex items-center gap-[5px] pb-[3px] tab-box"}>
                                        <span>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2.375 9.5H3.16667M9.5 2.375V3.16667M15.8333 9.5H16.625M4.43333 4.43333L4.9875 4.9875M14.5667 4.43333L14.0125 4.9875"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M7.12435 12.6665C6.45972 12.168 5.96878 11.4731 5.72107 10.6801C5.47335 9.88709 5.48143 9.03625 5.74414 8.2481C6.00686 7.45995 6.5109 6.77444 7.18487 6.28867C7.85884 5.8029 8.66856 5.5415 9.49935 5.5415C10.3301 5.5415 11.1399 5.8029 11.8138 6.28867C12.4878 6.77444 12.9918 7.45995 13.2546 8.2481C13.5173 9.03625 13.5253 9.88709 13.2776 10.6801C13.0299 11.4731 12.539 12.168 11.8743 12.6665C11.5653 12.9725 11.3325 13.3469 11.195 13.7595C11.0575 14.1721 11.019 14.6113 11.0827 15.0415C11.0827 15.4614 10.9159 15.8642 10.6189 16.1611C10.322 16.458 9.91928 16.6248 9.49935 16.6248C9.07942 16.6248 8.6767 16.458 8.37976 16.1611C8.08283 15.8642 7.91602 15.4614 7.91602 15.0415C7.97971 14.6113 7.94123 14.1721 7.8037 13.7595C7.66617 13.3469 7.43344 12.9725 7.12435 12.6665"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M7.67969 13.4585H11.3214"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <span>Tips</span>
                                    </span>
                                </Link>
                            </div>
                        </div>

                    ) : (
                        <>
                            <div className='left-right-nav'>

                                <div className='left-nav'>

                                    <Link to="/">
                                        <img src={logo} alt="Wohohiame Logo" className="logo" />
                                    </Link>
                                    <Link to="/" className={`nav-link ${pathname === "/" ? "active" : "inactive"}`}>
                                        <span className={"flex items-center gap-[5px] pb-[3px]"}>
                                            <span>
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M3.95833 9.5H2.375L9.5 2.375L16.625 9.5H15.0417"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M3.95898 9.5V15.0417C3.95898 15.4616 4.1258 15.8643 4.42273 16.1613C4.71966 16.4582 5.12239 16.625 5.54232 16.625H13.459C13.8789 16.625 14.2816 16.4582 14.5786 16.1613C14.8755 15.8643 15.0423 15.4616 15.0423 15.0417V9.5"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M7.125 16.6248V11.8748C7.125 11.4549 7.29182 11.0522 7.58875 10.7553C7.88568 10.4583 8.28841 10.2915 8.70833 10.2915H10.2917C10.7116 10.2915 11.1143 10.4583 11.4113 10.7553C11.7082 11.0522 11.875 11.4549 11.875 11.8748V16.6248"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                            <span>Home</span>
                                        </span>
                                    </Link>
                                    {/* <Link onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} to="/about-us" className={`nav-link ${pathname === "/about-us" ? "active" : "inactive"}`}>
                                        <span className={"flex items-center gap-[5px] pb-[3px] tab-box"}>
                                            <img src={isHovered || pathname === "/about-us" ? aboutBlue : about} className='about-icon' />
                                            <span>About</span>
                                        </span>
                                    </Link> */}
                                    <Link to="/info-advice" className={`nav-link ${isActive("info-advice") ? "active" : ""}`}>
                                        <span className={"flex items-center gap-[5px] pb-[3px] tab-box"}>
                                            <span>
                                                <svg
                                                    width="17"
                                                    height="20"
                                                    viewBox="0 0 17 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_409_5379)">
                                                        <path
                                                            d="M10.0001 0.90918H2.72736C1.72736 0.90918 0.90918 1.72736 0.90918 2.72736V17.2728C0.90918 18.2728 1.72736 19.091 2.72736 19.091H13.6365C14.6365 19.091 15.4546 18.2728 15.4546 17.2728V6.36372L10.0001 0.90918Z"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M10 0.90918V6.36373H15.4545"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M11.8186 10.9092H4.5459"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M11.8186 14.5459H4.5459"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M6.36408 7.27246H5.45499H4.5459"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_409_5379">
                                                            <rect width="16.3636" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span>Info & Advice</span>
                                        </span>
                                    </Link>
                                    <Link to="/tips" className={`nav-link ${isActive("tips") ? "active" : ""}`}>
                                        <span className={"flex items-center gap-[5px] pb-[3px] tab-box"}>
                                            <span>
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M2.375 9.5H3.16667M9.5 2.375V3.16667M15.8333 9.5H16.625M4.43333 4.43333L4.9875 4.9875M14.5667 4.43333L14.0125 4.9875"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M7.12435 12.6665C6.45972 12.168 5.96878 11.4731 5.72107 10.6801C5.47335 9.88709 5.48143 9.03625 5.74414 8.2481C6.00686 7.45995 6.5109 6.77444 7.18487 6.28867C7.85884 5.8029 8.66856 5.5415 9.49935 5.5415C10.3301 5.5415 11.1399 5.8029 11.8138 6.28867C12.4878 6.77444 12.9918 7.45995 13.2546 8.2481C13.5173 9.03625 13.5253 9.88709 13.2776 10.6801C13.0299 11.4731 12.539 12.168 11.8743 12.6665C11.5653 12.9725 11.3325 13.3469 11.195 13.7595C11.0575 14.1721 11.019 14.6113 11.0827 15.0415C11.0827 15.4614 10.9159 15.8642 10.6189 16.1611C10.322 16.458 9.91928 16.6248 9.49935 16.6248C9.07942 16.6248 8.6767 16.458 8.37976 16.1611C8.08283 15.8642 7.91602 15.4614 7.91602 15.0415C7.97971 14.6113 7.94123 14.1721 7.8037 13.7595C7.66617 13.3469 7.43344 12.9725 7.12435 12.6665"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M7.67969 13.4585H11.3214"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                            <span>Tips</span>
                                        </span>
                                    </Link>
                                </div>
                                <div className='right-nav'>
                                    <SearchButton onSearchClick={handleSearchButtonClick} />
                                </div>
                            </div>
                        </>
                    )}

                    <div className='search-bar'>
                        {showSearchBar && <SearchBar setShowSearchBar={setShowSearchBar} />}
                    </div>

                </div>

            </div>
            <div className='below-nav drop-shadow'>
                {hasAtLeastTwoParams && (
                    <div className={"bread-wrapper"}>
                        <BreadCrumbs />
                    </div>
                )}
                {hasAtLeastTwoParams && (
                    <div className={"breadcrumbs-wrapper"}>
                        <div className={"bread-audio"}>
                            {hasAtLeastThreeParams && (
                                <div className="audio-button">
                                    <audio id="englishVoiceover" src={map.get(englishAudioPath)}></audio>
                                    <audio id="twiVoiceover" src={map.get(twiAudioPath)}></audio>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className=" w-5 h-5">
                                        <path d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM18.584 5.106a.75.75 0 011.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 11-1.06-1.06 8.25 8.25 0 000-11.668.75.75 0 010-1.06z" />
                                        <path d="M15.932 7.757a.75.75 0 011.061 0 6 6 0 010 8.486.75.75 0 01-1.06-1.061 4.5 4.5 0 000-6.364.75.75 0 010-1.06z" />
                                    </svg>
                                    <button className="text-lg font-bold mx-1.5 underline" onClick={() => playAudio(true)
                                    }>En</button>
                                    <div className="dash"> | </div>
                                    <button className="text-lg font-bold ml-1.5 underline" onClick={() => playAudio(false)
                                    }>Twi</button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Nav;
