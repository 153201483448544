import sad from "../../assets/FeelingImages/cryin_icon.png";
import lonely from "../../assets/FeelingImages/sad_icon.png";
import good from "../../assets/FeelingImages/smile_good.png";
import stressed from "../../assets/FeelingImages/stressed_icon.png";
import tired from "../../assets/FeelingImages/tired_icon.png";
import { Link } from 'react-router-dom';

export function transformBodyWithLinks(body) {
    // Define the phrases and their respective links.
    const linksMap = {
        'self-care': '/tips/taking-care-of-yourself/self-care',
        'how to boost your mood here.': '/tips/finding-your-inner-calm/boost-your-mood',
        'read more about depression.': '/info-advice/feelings-and-emotions/depression',
        'anxiety and ways to cope with it.': '/info-advice/feelings-and-emotions/anxiety',
        'our tips on how to find your inner calm.': '/tips/finding-your-inner-calm',
        'our article on relationships.': '/info-advice/you-and-others/relationships',
        'are you getting enough sleep?': '/tips/taking-care-of-yourself/sleep',
        'what you eat affects your mental health?': '/tips/taking-care-of-yourself/basics-of-a-healthy-diet',
        'are you exercising enough?': '/tips/finding-your-inner-calm/physical-exercise',

        // Add more phrases and their links here as needed.
    };

    const lines = body.split('\n');

    return lines.map(line => {
        const parts = line.split(/(\[[^\]]+\])/).filter(Boolean);
        return parts.map(part => {
            if (part.startsWith('[') && part.endsWith(']')) {
                const phrase = part.slice(1, -1);  // Remove brackets
                const link = linksMap[phrase.toLowerCase()]; // Get the link
                if (link) {
                    return <Link to={link} className="hint-link" key={phrase}>{phrase}</Link>;
                }
            }
            return part;
        });
    });
}

export const FeelingsData = {
    feelings: [
        {
            id: 1,
            feeling: "Good",
            title: "Feeling good today? That’s great news! ",
            image: good,
            body: "Don’t forget to practice [self-care] on a regular basis.",
        },
        {
            id: 2,
            feeling: "Sad",
            title: "Sadness is an emotion that we all feel at certain times in our lives.",
            image: sad,
            body: "Like any emotion, it is temporary, and there are things you can do about it. Read up on [how to boost your mood here.]\nSometimes you might say you are 'depressed,' but it is important not to confuse sadness with depression. [Read more about depression.]",
        },
        {
            id: 3,
            feeling: "Stressed",
            title: "Many things in life can trigger stress, so it is important to know how to manage your anxiety.",
            image: stressed,
            body: "Read about [anxiety and ways to cope with it.]\nYou may also want to check out [our tips on how to find your inner calm.]",
        },
        {
            id: 4,
            feeling: "Lonely",
            title: "Social interactions are complicated, and it is normal to feel lonely from time to time.",
            image: lonely,
            body: "It may be because you are facing issues with your partner. If so, you may find some useful information in [our article on relationships.]\nSometimes it is simply because making friends can be challenging. For this too, we might be able to help.",
        },
        {
            id: 5,
            feeling: "Tired",
            title: "Do you feel like you have no energy? Having a healthy lifestyle is crucial in order to be on the top of your game. So, do a quick check:",
            image: tired,
            body: "[Are you getting enough sleep?]\n[Are you exercising enough?]\nDid you know that [what you eat affects your mental health?]",
        }
    ]
}
