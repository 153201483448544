import React from 'react';

const FadeUp = ({ children, className, duration, easing, mirror, once, offset, anchorPlacement, id }) => {
    return (
        <div
            className={className}
            data-aos="fade-up"
            data-aos-duration={duration}
            data-aos-easing={easing}
            data-aos-mirror={mirror}
            data-aos-once={once}
            data-aos-offset={offset}
            data-aos-anchor-placement={anchorPlacement}
            id={id}
        >
            {children}
        </div>
    );
};

FadeUp.defaultProps = {
    duration: '1500',
    easing: 'ease-in-out',
    mirror: false,
    once: true,
    offset: '-50',
    anchorPlacement: 'top-bottom',
};

export default FadeUp;
