import { useState } from "react";
import dialogError from "../../assets/Images/dialog-error.248x256.png";
import { Link } from "react-router-dom";
import NeuButton from "../Buttons/NeuButton/NeuButton";
import emails from "./sendEmail";
import emailjs from "emailjs-com";
import contactSucess from "../../assets/Images/contact-success.png";
import spinner from "../../assets/Images/spinner.gif";

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleSubjectChange = (e) => setSubject(e.target.value);
    const handleMessageChange = (e) => setMessage(e.target.value);

    const validateForm = () => {
        const errors = [];

        if (!name) {
            errors.push("Name is required");
        } else if (name.length > 100) {
            errors.push("Name must be less than 100 characters");
        } else if (name.length < 2) {
            errors.push("Name must be at least 2 characters")
        }

        if (!email) {
            errors.push("Email is required");
        }

        if (!subject) {
            errors.push("Subject is required");
        } else if (subject.length > 150) {
            errors.push("Subject must be less than 150 characters");
        } else if (subject.length < 2) {
            errors.push("Subject must be at least 2 characters")
        }

        if (!message) {
            errors.push("Message is required");
        } else if (message.length > 600) {
            errors.push("Message must be less than 600 characters");
        } else if (message.length < 2) {
            errors.push("Message must be at least 2 characters")
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevents default form submission

        const validationErrors = validateForm();
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoading(true);

        emailjs
            .sendForm(
                emails("service"),
                emails("template"),
                e.target,
                emails("public")
            )
            .then(
                (result) => {
                    console.log("Successfully submitted form!");
                    setLoading(false);
                    setEmailSent(true);
                },
                (error) => {
                    console.log(error.text);
                    setLoading(false);
                }
            );
    };

    return (
        <div className="flex flex-col md:flex-row justify-center items-start gap-2 bg-white pt-6 mt-6 mb-[200px] px-10 rounded-lg mx-auto max-w-4xl shadow-[-10px_-10px_15px_rgba(255,_255,_255,_0.8),_10px_10px_20px_rgba(0,_0,_0,_0.3)]">
            <div className="w-full px-2 mb-6 flex flex-col justify-center items-center">
                <h2 className="text-3xl font-bold text-blue text-center">Contact Us</h2>
                {errors.length > 0 && (
                    <div className="flex flex-col items-center">
                        <img src={dialogError} alt="Error icon" width={50} height={50} />
                        <ul>
                            {errors.map((error, idx) => (
                                <li key={idx} className="text-red-500 text-lg">{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {emailSent ? (
                    <div className="flex gap-8 items-center mt-5">
                        <div className="success-message text-center">
                            <h2 className="text-2xl font-bold text-black">Your message was sent successfully!</h2>
                            <p>We will try to reply to you as soon as possible.</p>
                        </div>
                        <img
                            className={"rounded-full h-fit object-fill"}
                            loading="lazy"
                            src={contactSucess}
                            alt="success"
                        />
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="flex flex-col items-center w-1/2">
                        <div className="input-label w-full text-left">
                            <label htmlFor="name" className="block mb-2 text-l font-bold text-black">Name</label>
                            <input type="text" name="name" placeholder="Your Name" value={name} onChange={handleNameChange} className="input text-base px-3 py-2 rounded-md w-full mb-6 border border-black focus:ring-blue focus:border-blue" />
                        </div>
                        <div className="input-label w-full text-left">
                            <label htmlFor="email" className="block mb-2 text-l font-bold text-black">Email</label>
                            <input type="email" name="email" placeholder="Your Email" value={email} onChange={handleEmailChange} className="input text-base px-3 py-2 rounded-md w-full mb-6 border border-black focus:ring-blue focus:border-blue" />
                        </div>
                        <div className="input-label w-full text-left">
                            <label htmlFor="subject" className="block mb-2 text-l font-bold text-black">Subject</label>
                            <input type="text" name="subject" placeholder="Subject" value={subject} onChange={handleSubjectChange} className="input text-base px-3 py-2 rounded-md w-full mb-6 border border-black focus:ring-blue focus:border-blue" />
                        </div>
                        <div className="input-label w-full text-left">
                            <label htmlFor="message" className="block mb-2 text-l font-bold text-black">Message</label>
                            <textarea name="message" placeholder="Message" value={message} onChange={handleMessageChange} className="textarea text-base px-3 py-2 rounded-md w-full mb-6 h-48 border border-black focus:ring-blue focus:border-blue"></textarea>
                        </div>
                        <div className="mx-auto mb-[15px]">
                            <NeuButton fontWeight="700" bg="#2469A6" titleColor="white">
                                {loading ? (
                                    <img src={spinner} style={{"height": "20px"}}/>
                                ) : (
                                    <span style={{ color: 'white', fontWeight: '700' }}>Send Message</span>

                                )}
                            </NeuButton>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

export default Contact;
