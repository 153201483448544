import React, { useState } from "react";
import InspiredCard from "./InspiredCard/InspiredCard"
import { Link } from "react-router-dom";
import "./Inspired.css";
import { InnerCalmData } from "../../../data/Articles/Tips/Tips";
import NeuButton from "../../Buttons/NeuButton/NeuButton";
import CardCarousel from "../../CardCarousel/CardCarousel";
import FadeLeft from "../../../utils/FadeLeft";
import FadeUp from "../../../utils/FadeUp";

const Inspired = () => {

    return (
        // <div className="inspired-wrapper w-full" style={{
        //     backgroundImage: 'linear-gradient(to right, #2469A6, #1E5C91, #1A507D, #154468, #103954)',
        // }}>
        <div className="inspired-wrapper w-full relative gradient-background">
            {/* <div className="gradient" /> */}
            <div className="inspired-container pb-5">
                {/* <h2 data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top-bottom"
                    className={"inspired-title text-center font-bold text-blue"}>Get Inspired</h2>
                <div
                    className={
                        "flex gap-4 md:justify-between"
                    }
                >
                    {InnerCalmData.articles &&
                        InnerCalmData.articles.map((inspiration, index) => (
                            <InspiredCard
                                //   setHeightState={heightStateHandler}
                                //   heightState={heightState}
                                inspiration={inspiration}
                                key={index}
                            />
                        ))}
                </div> */}
                <CardCarousel page={"tips"} title={"Get Inspired!"}/>
                <div className={"find-inspo"} >
                    <div className="mx-auto mt-[15px] mb-[25px]">
                        <Link className="browse-button" to="/tips"><NeuButton fontWeight="700" bg="#2469A6" titleColor="white" title={"Find more inspiration"} /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inspired;
