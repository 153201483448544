import React, { useEffect, useState } from "react";
import ArticleTeaser from "../ArticleTeaser/ArticleTeaser";
import urlFor from "../../utils/SanityImage";
import { useLocation } from "react-router";
import "./SearchResults.css";
import SearchResultsBar from "../SearchResultsBar/SearchResultsBar";
import Loading from "../Loading/Loading";
import NoResults from "../../assets/Images/no-results.png";

const SearchResults = () => {
    const { search } = useLocation(); // To get the query string from the URL
    const queryParams = new URLSearchParams(search);
    const searchPhrase = queryParams.get("q"); // Assuming your search query parameter is 'q'
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    useEffect(() => {
        window.scrollTo(0, 0);
        if (searchPhrase) {
            setIsLoading(true); // Start loading
            const projectId = 'fsyqboh0';
            const dataset = 'production';
            const query = `*[_type == "paragraph" && body[].content[].children[].text match "${searchPhrase}*"]{ title, body, article }`;
            const url = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=${encodeURIComponent(query)}`;

            fetch(url)
                .then(response => response.json())
                .then(({ result }) => {
                    if (result && result.length > 0) {
                        const articleIds = result
                            .flatMap(item => item.article) // Flatten all article reference arrays into a single array
                            .map(ref => ref._ref) // Extract the _ref values which are the article IDs
                            .filter((value, index, self) => self.indexOf(value) === index); // Filter out duplicate IDs

                        if (articleIds.length > 0) {
                            const articlesQuery = `*[_id in [${articleIds.map(id => `"${id}"`).join(',')}]]{
                                _id,
                                title,
                                "slug": slug.current,
                                mainImage,
                                summary,
                                teaser,
                                "url": "/tips/" + categories[0]->slug.current + "/" + slug.current
                            }`;
                            const articlesUrl = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=${encodeURIComponent(articlesQuery)}`;

                            return fetch(articlesUrl).then(response => response.json());
                        }
                    }
                    // No articles found or no result from the paragraph query, return an empty array to stop loading
                    return { result: [] };
                })
                .then(({ result: articlesResult }) => {
                    setArticles(articlesResult || []); // Handle undefined results
                    setIsLoading(false); // Stop loading
                })
                .catch(error => {
                    console.error('Error fetching articles:', error);
                    setError('Error loading articles data');
                    setIsLoading(false); // Ensure loading is stopped in case of error
                });
        } else {
            // This else block is for when there's no search phrase, you might want to set loading to false here as well
            setIsLoading(false);
        }
    }, [searchPhrase]);

    // console.log(searchPhrase);
    return (
        <div className={"category-hub-wrapper pb-[150px]"}>
            <div className="search-res-container">
                <div className={"my-3 text-2xl"}>
                    <h1>Search results</h1>
                </div>
                <SearchResultsBar searchPhrase={searchPhrase} />
                {isLoading ? (
                    <Loading />
                ) : (<>
                    <div>
                        {searchPhrase && (
                            <span>
                                {articles.length} result{articles.length !== 1 ? 's' : ''} for "<span className="font-bold text-blue">{searchPhrase}</span>"
                            </span>
                        )}
                    </div>
                    <div>{error && <div>{error}</div>}</div>
                    <div className={"text-black"}>
                        <div className={`teaser-container-search pb-[50px]`} style={{ minHeight: "200px" }}>
                            {articles.length > 0 ? (
                                articles.map((article) => (
                                    <ArticleTeaser article={article} key={article.slug} />
                                ))
                            ) : (
                                <div className="teaser-container-search">
                                    <div className="no-results-text">
                                        <h3 className="text-black text-2xl">Oops!</h3>
                                        <p className="text-l text-center">
                                            Seems like nothing matched your search.
                                            Try again with some different keywords.
                                        </p>
                                    </div>
                                    <div className="no-results-image">
                                        <img
                                            className={"rounded-full h-fit object-fill"}
                                            loading="lazy"
                                            src={NoResults}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
                )}
            </div>
        </div>
    );
};

export default SearchResults;
