// ScrollToTop.js
import React, { useState, useEffect } from 'react';

const ScrollToTop = ({ showBelow }) => {
    const [show, setShow] = useState(false);
    const [rightMargin, setRightMargin] = useState('45px');

    useEffect(() => {
        if (!showBelow) return;

        const updateRightMargin = () => {
            const width = window.innerWidth;

            if (width > 1700) {
              setRightMargin('245px');
            } else if (width > 1240) {
              // Linearly interpolate between 1240px and 1700px
              const ratio = (width - 1240) / (1700 - 1240);
              const right = ratio * 245; // Calculate proportional right value
              setRightMargin(`${right}px`);
            } else if (width > 768) {
              setRightMargin('45px');
            } else {
              setRightMargin('15px');
            }
          };

        const handleScroll = () => {
            if (window.pageYOffset > showBelow) {
                if (!show) setShow(true);
            } else {
                if (show) setShow(false);
            }
        };

        window.addEventListener(`scroll`, handleScroll);
        window.addEventListener('resize', updateRightMargin);

        updateRightMargin();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', updateRightMargin);
        };

    }, [show, showBelow]);

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` });
    };

    return (
        show && (
            <button
                onClick={handleClick}
                style={{
                    position: 'fixed',
                    right: rightMargin,
                    bottom: '40px',
                    width: '55px',
                    height: '55px',
                    borderRadius: '50%',
                    textAlign: 'center',
                    backgroundColor: '#2469A6',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    fontSize: '25px',
                    zIndex: '20',
                }}
            >
                ↑
            </button>
        )
    );
};

export default ScrollToTop;
