import React from "react";
import { useNavigate, Link } from "react-router-dom";
import "../SearchResults/SearchResults.css";


const SearchResultsBar = ({ searchPhrase }) => {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission
        const searchInput = e.target.elements.searchInput.value.trim(); // Get the trimmed value of the search input
        if (searchInput) {
            // Redirect to the search results page with the search query parameter
            navigate(`/search-results?q=${encodeURIComponent(searchInput)}`);

        }
    };
    return (
        <form className="searchFormRes" onSubmit={handleSubmit}>
            <input
                aria-label="Search Text Field"
                autoFocus={true}
                name="searchInput"
                // ref={searchRef}
                className="searchInputRes px-4 text-l h-9 translate-x-2 border rounded-full"
                type="text"
                placeholder={searchPhrase ? `Search results for "${searchPhrase}"` : "Type in keywords you want to search..."}
            />
            <button className={"-translate-x-8"} >
                <svg
                    width="20"
                    height="17"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M19.875 19.875L13.6251 13.625M15.7083 8.41667C15.7083 12.4437 12.4437 15.7083 8.41667 15.7083C4.38959 15.7083 1.125 12.4437 1.125 8.41667C1.125 4.38959 4.38959 1.125 8.41667 1.125C12.4437 1.125 15.7083 4.38959 15.7083 8.41667Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </form>
    )
}

export default SearchResultsBar
