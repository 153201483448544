import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import GrayLinkContainer from '../GrayLinkContainer/GrayLinkContainer';
import "./ArticleHub.css";
import Loading from '../Loading/Loading';

const ArticleHub = () => {
  let { pathname } = useLocation();
  const firstElementInPath = pathname.split("/")[1];
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  const [categoriesMap, setCategoriesMap] = useState(new Map());

  useEffect(() => {
    setIsLoading(true); // Set loading to true when component mounts or firstElementInPath changes
    const projectId = 'fsyqboh0';
    const dataset = 'production';
    const url = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=`;

    const fetchArticles = async () => {
      try {
        const articlesResponse = await fetch(`${url}${encodeURIComponent('*[_type == "article"]{title, "slug": slug.current, "categoryId": categories[0]->_id, "categoryTitle": categories[0]->title, "categorySlug": categories[0]->slug.current, page}')}`);
        const articlesData = await articlesResponse.json();
        // console.log("artData: ", articlesData)

        if (articlesData.result) {
          const tempCategoriesMap = new Map();

          articlesData.result.forEach(article => {
            // Check if the article is not "About us" before adding it
            if (article.title.toLowerCase() !== "about us") {
              const { categoryId, categoryTitle, categorySlug, page } = article;
              if (page === firstElementInPath) { // Further filter by matching page to firstElementInPath
                if (!tempCategoriesMap.has(categoryId)) {
                  tempCategoriesMap.set(categoryId, { title: categoryTitle, slug: categorySlug, articles: [], page: page });
                }
                tempCategoriesMap.get(categoryId).articles.push(article);
              }
            }
          });

          setCategoriesMap(tempCategoriesMap);
        }
      } catch (err) {
        console.error('Fetch error:', err);
        setError('Error loading articles');
      } finally {
        setIsLoading(false); // Set loading to false after fetching data
      }
    };

    fetchArticles();
  }, [firstElementInPath]);



  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return <div className='art-hub-wrapper'>
      <Loading />
    </div>
  }

  const categories = Array.from(categoriesMap.values());
  // console.log("categories: ", categories);

  return (
    <>
      <div className={"art-hub-wrapper px-4 md:px-0 pt-[15px] mx-auto"}>
        <section className={"title-and-desc"}>
          <h1 className={`text-left mb-[5px] `}>
            {firstElementInPath === "info-advice" && "Info & Advice"}
            {firstElementInPath === "tips" && "Tips"}
          </h1>
          <p>{firstElementInPath === "tips" ? "Sometimes it can be difficult to put into practice advice you get. We have put together some practical tips and activities to help you take care of yourself." : "All the information and support you need in one place."}</p>
        </section>
        <section className={"gray-links-wrapper mt-2 gap-4"}>
          {categories.map((category, idx) => (
            <GrayLinkContainer
              key={idx + category.title}
              category={category}
              section={firstElementInPath}
            />
          ))}
        </section>
      </div>
      {/* <section id={"topics"} className={"mt-[25px]"}>
        <GradientCommunicationSection
          url={undefined}
          title={"Do you want to know about other topics?"}
          buttonText={"Send us suggestions!"}
        />
      </section> */}
    </>
  )
}

export default ArticleHub;
