import React from 'react';
import FadeUp from '../../utils/FadeUp';
import SubParagraph from '../SubParagraph/subParagraph';
import BlueContainerContent from '../BlueContainerContent/blueContainerContent';
import "./Paragraph.css"
import FadeLeft from '../../utils/FadeLeft';
import FadeRight from '../../utils/FadeRight';

const Paragraph = ({ paragraph, paragraphImage, paragraphBlues, blueContainerContent, articleTitle, urlFor, groupImageWithBlueContainer, isGrouped, blueOnTop }) => {

    const imageBlock = paragraph.body.find(block => block._type === 'image');
    const hasImage = Boolean(imageBlock);
    // console.log("paragraph: ", paragraph);

    const normalizedArticleTitle = articleTitle.replace(/\s+/g, '-').toLowerCase();
    // console.log("norm: ", normalizedArticleTitle)

    const isSubPSmall = (serialNum) => {
        return paragraphImage[normalizedArticleTitle]?.includes(serialNum) || paragraphBlues[normalizedArticleTitle]?.includes(serialNum);
    };

    const blueShouldBeOnTop = blueOnTop[articleTitle] === true;

    // Check if the current paragraph should have blue container content
    const hasBlueContainer = paragraph.body.some(subP => paragraphBlues[normalizedArticleTitle]?.includes(subP.serial_num));
    const shouldRenderStandaloneBlueContainer = hasBlueContainer && !groupImageWithBlueContainer;
    // console.log("groupIwB: ", paragraph.serial_num, groupImageWithBlueContainer);
    // console.log("standBlue: ", shouldRenderStandaloneBlueContainer);
    // console.log("pImage: ", paragraphImage);
    // console.log("pBlue: ", paragraphBlues[normalizedArticleTitle])
    // console.log("isGroup: ", paragraph.serial_num, isGrouped)
    // Determine if subparagraph should be rendered outside of any special group
    // const shouldRenderSubPStandalone = (subP) => {
    //     return !isSubPSmall(subP.serial_num) || (groupImageWithBlueContainer && !paragraphImage[articleTitle].includes(subP.serial_num));
    // };
    const shouldRenderSubPStandalone = (subP) => {
        if (isGrouped) {
            // Exclude subparagraphs in paragraphBlues array when isGrouped is true
            return !paragraphBlues[normalizedArticleTitle]?.includes(subP.serial_num);
        } else {
            return !isSubPSmall(subP.serial_num) || (groupImageWithBlueContainer && !paragraphImage[normalizedArticleTitle]?.includes(subP.serial_num));
        }
    };

    return (
        <div id={paragraph.title.replace(/\s+/g, '-').toLowerCase()} className="article-p-wrapper">
            <div className="paragraph-container">
                <div className="p-title">
                    {paragraph.title}
                </div>

                {hasImage && groupImageWithBlueContainer && (
                    <div className="subP-ib-container">
                        <div className="subP-ib">
                            {paragraph.body.filter(subP => subP.serial_num && paragraphImage[normalizedArticleTitle]?.includes(subP.serial_num))
                                .map(subP => <SubParagraph key={subP._key} subParagraph={subP} className="subP-small" />)
                            }
                        </div>
                        <div className='image-and-blue'>
                            <div className="rounded-lg anxiety-image-container">
                                <img id="art-image" className="rounded-lg" src={urlFor(imageBlock.asset)} alt={`${articleTitle} Image`} />
                            </div>
                            {hasBlueContainer &&
                                <div>
                                    <BlueContainerContent blueContainerContent={blueContainerContent} articleTitle={normalizedArticleTitle} containerIndex={0} />
                                </div>}
                        </div>
                    </div>
                )}

                {hasImage && blueShouldBeOnTop && groupImageWithBlueContainer && (
                    <div className="subP-ib-container">
                        <div className="subP-ib">
                            {paragraph.body.filter(subP => subP.serial_num && paragraphImage[normalizedArticleTitle]?.includes(subP.serial_num))
                                .map(subP => <SubParagraph key={subP._key} subParagraph={subP} className="subP-small" />)
                            }
                        </div>
                        <div className='image-and-blue'>
                            {hasBlueContainer &&
                                <div>
                                    <BlueContainerContent blueContainerContent={blueContainerContent} articleTitle={normalizedArticleTitle} containerIndex={0} />
                                </div>}
                            <div className="rounded-lg anxiety-image-container">
                                <img id="art-image" className="rounded-lg" src={urlFor(imageBlock.asset)} alt={`${articleTitle} Image`} />
                            </div>
                        </div>
                    </div>
                )}


                {/* {hasImage && !groupImageWithBlueContainer && (
                    <FadeUp className="rounded-lg image-alone-container">
                        <img className="rounded-lg" src={urlFor(imageBlock.asset)} alt={`${articleTitle} Image`} />
                    </FadeUp>
                )} */}

                {hasImage && !groupImageWithBlueContainer && (
                    <div className="subP-i-container">
                        <div className="subP-b">
                            {paragraph.body.filter(subP => isSubPSmall(subP.serial_num) && paragraphImage[normalizedArticleTitle]?.includes(subP.serial_num)).map(subP => (
                                <SubParagraph key={subP._key} subParagraph={subP} className="subP-small" />
                            ))}
                        </div>
                        <div className="rounded-lg image-alone-container">
                            <img id="art-image" className="rounded-lg" src={urlFor(imageBlock.asset)} alt={`${normalizedArticleTitle} Image`} />
                        </div>
                    </div>
                )}

                {shouldRenderStandaloneBlueContainer && (
                    <div className="subP-b-container">
                        <div className="subP-b">
                            {paragraph.body.filter(subP => isSubPSmall(subP.serial_num) && paragraphBlues[normalizedArticleTitle]?.includes(subP.serial_num)).map(subP => (
                                <SubParagraph key={subP._key} subParagraph={subP} className="subP-small" />
                            ))}
                        </div>
                        {!isGrouped && (
                            <div className='blue-content-alone'>
                                <BlueContainerContent blueContainerContent={blueContainerContent} articleTitle={normalizedArticleTitle} containerIndex={blueContainerContent[1] ? 1 : 0} />
                            </div>
                        )}
                    </div>
                )}

                {paragraph.body.filter(shouldRenderSubPStandalone).map(subP => (
                    subP._key && subP.serial_num && <SubParagraph key={subP._key} subParagraph={subP} className="subP-full" />
                ))}

            </div>
        </div>
    );
};

export default Paragraph;
