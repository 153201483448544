import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import urlFor from "../../utils/SanityImage";
import "./ArticleTeaser.css";


const ArticleTeaser = ({ article }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const smallHeight = Math.ceil(windowWidth / 2.9);
    const smallWidth = Math.ceil(windowWidth / 2);

    // console.log("article: ", article);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`teaser-wrapper my-6 h-fit`}>
            <div
                className={"tease-img-wrapper h-fit"}>
                {windowWidth > 768 ? (
                    <Link to={article.url}>
                        <img
                            className={"article-image rounded-t-xl object-cover"}
                            loading="lazy"
                            src={urlFor(article.mainImage)}
                            style={{ height: "200px" }}
                            alt=""
                        />
                    </Link>
                ) : (
                    <Link to={article.url}>
                        <img
                            className={"article-image rounded-xl h-fit object-fill"}
                            loading="lazy"
                            src={urlFor(article.mainImage)}
                            alt=""
                            style={{ height: smallHeight }}
                        />
                    </Link>
                )}
            </div>
            <div className={"teaser-desc md:w-[400px] col-span-1 w-full px-2 py-4"}>
                <Link
                    style={{ lineHeight: "23px" }}
                    to={article.url}
                    className={"article-title text-blue text-[18px] font-black no-underline"}
                >
                    {article.title}
                </Link>
                <p className={"article-desc text-[14px] rounded-b-lg"} style={{ padding: "0 0", margin: "0 0", lineHeight: "16px" }}>
                    {article.teaser}
                </p>
            </div>
        </div>
    );
};

export default ArticleTeaser;
