import React from 'react';
import FadeUp from '../../utils/FadeUp';
import BlockContent from "@sanity/block-content-to-react";
import "./subParagraph.css";

const SubParagraph = ({ subParagraph, className }) => {
    // console.log("subParagraph: ", subParagraph);
    const subPText = subParagraph?.content?.map(block => block.children.map(span => span.text).join('')).join('');
    const serializers = {
        types: {
            block: props => {
                // Handle different styles, lists, etc.
                if (props.node.style === 'normal') {
                    return <div>{props.children}</div>;
                }
                // Add more cases as needed for different styles

                return BlockContent.defaultSerializers.types.block(props);
            },

        },
        listItem: props => {
            return <li className='subP-li'>{props.children}</li>;
        },
        marks: {
            link: ({ mark, children }) => {
                // Handle links
                const { href } = mark;
                return <a className="subP-link" href={href}>{children}</a>;
            }
            // Add more serializers for other types of annotations if needed
        }
    };

    return (
        <div key={subParagraph._key} className={`p-text ${className}`}>
            {subParagraph.title && <div className="p-header text-blue lg:text-xl mt-2">{subParagraph.title}</div>}
            {/* {subPText} */}
            <BlockContent blocks={subParagraph.content} serializers={serializers} />
        </div>
    );
};

export default SubParagraph;
