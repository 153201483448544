import React from "react";
import "./FeelingButton.css";

const FeelingButton = ({feelingButton, onClick, activeFeeling}) => {

    const activeBackground = activeFeeling === feelingButton ? "bg-light-blue" : "";
    // const activeShadow = activeFeeling === feelingButton.text ? "" : "shadow-lg";
    // const activeSize = activeFeeling === feelingButton.text ? "scale-95" : "";

    // const clickHandler = () => {
    //     onClick(feelingButton.text);
    // };

    return (
        <button
            style={{ borderRadius: '15px' }}
            onClick={() => onClick(feelingButton)}
            className={`${activeBackground} border-1 border-transparent py-2 px-3 flex items-center gap-2 shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.15)]

      transition-all

      hover:shadow-[-1px_-1px_5px_rgba(255,_255,_255,_0.6),_1px_1px_5px_rgba(0,_0,_0,_0.3),inset_-2px_-2px_5px_rgba(255,_255,_255,_1),inset_2px_2px_4px_rgba(0,_0,_0,_0.3)]`}
        >
            <img
                className={"h-6 w-6"}
                src={feelingButton.image}
                alt={feelingButton.feeling}
            />
            <span className="feeling-button-text">{feelingButton.feeling}</span>
        </button>
    );
};

export default FeelingButton;
