import "./Feeling.css";
import React, { useState } from "react";
import FeelingButton from "./FeelingButton/FeelingButton";
import FeelingHint from "./FeelingHint/FeelingHint";
import { Link } from "react-router-dom";
import { FeelingsData } from "../../../data/Feelings/FeelingsData";
import NeuButton from "../../Buttons/NeuButton/NeuButton";
import { AnimatePresence } from "framer-motion";

const Feeling = () => {
    const [selected, setSelected] = useState(null);

    const buttonClickHandler = (feeling) => {
        setSelected(feeling);
    };

    return (
        <div className="feeling-wrapper">
            <div className="flex flex-col w-full">
                <h2 className="text-center font-bold text-blue">
                    Hey, how are you doing?
                </h2>
                <p className="text-center md:hidden">
                    We may have some useful tips for you. Just tell us how you are
                    feeling, and we will try to help you :-)
                </p>
            </div>

            <div className="left-right md:flex lg:justify-center mt-[20px] w-full">
                <div className="left-feeling">
                    <div className="feeling-buttons h-fit gap-[15px]">
                        {FeelingsData.feelings.map((f) => (
                            <FeelingButton
                                onClick={buttonClickHandler}
                                key={f.title}
                                activeFeeling={selected ? selected : null}
                                feelingButton={f}
                            />
                        ))}
                    </div>
                    <div className="browse-topics">
                        <div className=" md:mx-auto mt-[15px] mb-[20px]">
                            <Link className="browse-button" to="/info-advice"><NeuButton fontWeight="700" bg="#2469A6" titleColor="white" title={"Browse specific topics"} /></Link>
                        </div>
                    </div>
                </div>
                <div className="right-feeling">
                    <AnimatePresence mode="wait">
                        {selected ? (
                            <FeelingHint key={selected.title} feeling={selected} />
                        ) : (
                            <p className="hidden md:block">
                                We may have some useful tips for you. Just tell us how you are
                                feeling, and we will try to help you :-)
                            </p>
                        )}
                    </AnimatePresence>
                </div>
                {/* {!selected && (
                    <div className="md:w-10/12 md:mx-auto mt-[15px] md:pl-3 mb-[20px]">
                        <Link to="/info-advice">Browse specific topics</Link>
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default Feeling;
