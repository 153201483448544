import React from 'react';
import FadeUp from '../../utils/FadeUp';
import BlockContent from "@sanity/block-content-to-react";
import "./blueContainerContent.css";

const BlueContainerContent = ({ blueContainerContent, containerIndex, articleTitle }) => {
    // Check if the index is within the bounds of the array
    if (containerIndex < 0 || containerIndex >= blueContainerContent?.length) {
        return null; // or you can return some default UI
    }

    let listItemIndex = 0; // Initialize a counter for list items

    const firstBlueItem = ["depression", "abusive-relationships"]

    // Access the specific container content
    const container = blueContainerContent[containerIndex];

    const serializers = {
        types: {
            block: props => {
                listItemIndex = 0;
                const isFirstListItem = listItemIndex === 0;
                if (props.node.style === 'normal') {
                    return <li className={`blue-li ${isFirstListItem && firstBlueItem.includes(articleTitle) ? 'no-bullet' : ''}`}>{props.children}</li>;
                }
                return BlockContent.defaultSerializers.types.block(props);
            }
        },
        listItem: props => {
            const isFirstListItem = listItemIndex === 0;
            listItemIndex++; // Increment the counter for each list item
            return <li className={`blue-li ${isFirstListItem && firstBlueItem.includes(articleTitle) ? 'no-bullet' : ''}`}>{props.children}</li>;
        },
        marks: {
            link: ({ mark, children }) => {
                const { href } = mark;
                return <a className="blue-link" href={href}>{children}</a>;
            }
        }
    };

    // Function to extract and concatenate text from the warning array
    const extractWarningText = (warningArray) => {
        return warningArray?.map(warningBlock => {
            // Ensure 'children' is an array before mapping over it
            return Array.isArray(warningBlock.children) ? warningBlock.children.map(span => span.text).join('') : '';
        }).join(' ');
    };

    // Extract warning text if available
    const warningText = container?.warning && extractWarningText(container.warning);
    // console.log("wT: ", warningText);

    return (
        <div id={container?.title.replace(/\s+/g, '-').toLowerCase()} className={"blue-container-wrapper bg-greenish-blue rounded-lg w-full p-3 lg:max-w-screen-xl mx-auto shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.15)]"}>
            <div className="b-title">
                {container?.title}
            </div>
            <ul className="blue-content">
                {/* {container?.body?.map((block, index) => (
                    <li key={index} className={`blue-li ${index === 0 ? firstListItemClass : ''}`}>
                        {block?.children?.map(span => span.text).join('')}
                    </li>
                ))} */}
                <BlockContent blocks={container?.body} serializers={serializers} />
            </ul>
            {container?.warning && (
                <div className="article-warn bg-[#DE304E] text-white px-2 pb-1 my-2">
                    {warningText}
                </div>
            )}
        </div>
    );
};

export default BlueContainerContent;
