import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import './BreadCrumbs.css';

function transformPathToRoutes(link) {
  return link.slice(1).split("/").map(segment => {
    const text = segment.split("-").map((str) =>
      str.charAt(0).toUpperCase() + str.slice(1)
    ).join(" ").replace("Info Advice", "Info & Advice");

    return {
      path: `/${segment}`,
      breadcrumb: text,
    };
  });
}

const BreadCrumbs = () => {
  const [breadcrumbsData, setBreadcrumbsData] = useState([]);
  let location = useLocation();

  useEffect(() => {
    setBreadcrumbsData(transformPathToRoutes(location.pathname));
  }, [location]);

  const breadcrumbs = useBreadcrumbs(breadcrumbsData);

  // Optionally remove the first breadcrumb if you don't want to show the Home path
  const displayBreadcrumbs = breadcrumbs.slice(1);

  return (
    <div className={""}>
      {displayBreadcrumbs.map(({ match, breadcrumb }, index) => (
        <span key={match.pathname}>
          <Link
            className={`${index !== displayBreadcrumbs.length - 1 ? "regBreadCrumbStyling" : "lastBreadCrumbStyling"}`}
            to={match.pathname}
          >
            {breadcrumb}
          </Link>
          {index !== displayBreadcrumbs.length - 1 && <span className="text-orange-800">{" > "}</span>}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumbs;
