import React from 'react';

const FadeUpRight = ({ children, className, duration, easing, mirror, once, offset, anchorPlacement }) => {
    return (
        <div
            className={className}
            data-aos="fade-up-right"
            data-aos-duration={duration}
            data-aos-easing={easing}
            data-aos-mirror={mirror}
            data-aos-once={once}
            data-aos-offset={offset}
            data-aos-anchor-placement={anchorPlacement}
        >
            {children}
        </div>
    );
};

FadeUpRight.defaultProps = {
    duration: '1000',
    easing: 'ease-in-out',
    mirror: false,
    once: false,
    offset: '0',
    anchorPlacement: 'top-bottom',
};

export default FadeUpRight;
