import React, { useContext } from "react";
import "./SearchButton.css";

const SearchButton = ({ onSearchClick }) => {

    const RoundedButton = ({ children, onClick, aLabel }) => {
        return (
            <button
                aria-label={aLabel}
                onClick={onClick}
                className={`bg-[#EDF3F8] font-black h-14 w-14 rounded-full hover:bg-blue hover:text-white
               flex justify-center items-center`}
            >
                <span className={"p-2.5 mx-auto"}>{children}</span>
            </button>
        );
    };

    return (
        <div className={"search-button-wrapper flex gap-2 md:gap-4"}>
            <RoundedButton aLabel={"Search Button"} onClick={onSearchClick}>
                <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M19.875 19.875L13.6251 13.625M15.7083 8.41667C15.7083 12.4437 12.4437 15.7083 8.41667 15.7083C4.38959 15.7083 1.125 12.4437 1.125 8.41667C1.125 4.38959 4.38959 1.125 8.41667 1.125C12.4437 1.125 15.7083 4.38959 15.7083 8.41667Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </RoundedButton>
        </div>
    );
};

export default SearchButton;
