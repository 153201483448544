
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArticleTeaser from "../ArticleTeaser/ArticleTeaser";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import "./CategoryHub.css";
import Loading from "../Loading/Loading";

const CategoryHub = () => {
    const [articles, setArticles] = useState([]);
    // const [categoryTitle, setCategoryTitle] = useState("");
    const [error, setError] = useState(null);

    let { section, category } = useParams();
    // console.log("catSlug: ", category)
    // console.log("section: ", section);

    useEffect(() => {
        const projectId = 'fsyqboh0'; // Replace with your actual project ID
        const dataset = 'production'; // Replace with your actual dataset
        const url = `https://${projectId}.api.sanity.io/v2021-10-21/data/query/${dataset}?query=`;

        const fetchArticles = async () => {
            try {
                const query = '*[_type == "article" && categories[0]->slug.current == "' + category + '"]{title, "slug": slug.current, mainImage, summary, teaser, "url": "/' + section + '/" + categories[0]->slug.current + "/" + slug.current}';
                const response = await fetch(`${url}${encodeURIComponent(query)}`);
                const data = await response.json();

                if (data.result) {
                    const filteredArticles = data.result.filter(article => {
                        // Transform the title to lowercase and replace spaces with dashes
                        const slugifiedTitle = article.title.toLowerCase().replace(/\s+/g, '-');
                        return slugifiedTitle !== "about-us";
                    });
                    setArticles(filteredArticles);
                } else {
                    setError('No articles found in this category');
                }
            } catch (err) {
                console.error('Fetch error:', err);
                setError('Error loading articles');
            }
        };

        fetchArticles();
    }, [category, section]);

    const categoryTitle = category
        .replace(/-/g, ' ') // Replace hyphens with spaces
        .toLowerCase() // Convert the whole string to lowercase
        .split(' ') // Split the string into an array of words
        .map((word, index) =>
            index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word) // Capitalize the first word only
        .join(' '); // Join the words back into a string

    // useEffect(() => {
    //     if (category) {
    //         document.title = categoryTitle + " | Wohohiame";
    //     }
    // }, [category]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!articles.length) {
        return <div className="category-hub-wrapper"><Loading /></div>;
    }

    return (
        <div className={"category-hub-wrapper"}>
            <div className="cat-hub-container">
                <div className={"chub-title w-full"}>
                    <h1 className={`text-left mx-auto`}>{categoryTitle}</h1>
                </div>
                <div className={`teaser-container`} style={{ minHeight: "200px" }}>
                    {articles.map((article) => (
                        <ArticleTeaser article={article} key={article.slug} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CategoryHub;
